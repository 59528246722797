import { Typography, Stack, styled, Breadcrumbs, Link } from "@mui/material";
import React from "react";
import App from "../components/App";
import Main from "../components/Main";
import TopImage from "../components/TopImage";
import Title from "../components/Title";
import CardForStamp from "../components/CardForStamp";

const Section = styled("div")({
  minWidth: 320,
  width: "100%",
});

const StampPage = () => {
  return (
    <App title="スタンプコンクリート｜株式会社トップクリートシステム社">
      <Main>
        <TopImage />
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            トップ
          </Link>
          <Typography fontWeight='bold'>スタンプコンクリート</Typography>
        </Breadcrumbs>
        <Stack sx={{ maxWidth: 1280, width: 1, margin: "auto", mt: 3, mb: 6 }}>
          <Section>
            <Title>スタンプコンクリートとは</Title>
            <Stack spacing={1} sx={{ p: 3, pt: 0 }}>
              <Typography whiteSpace="pre-wrap" variant="body1">
                {
                  "耐久性・施工性・デザイン性・経済性に優れ日々のメンテナンスもとても楽に行える景観コンクリート工法です。\n様々な用途に適し、心地よい華やかで温かい空間を創造できる新素材として注目されています。"
                }
              </Typography>
            </Stack>
            <Title>特徴</Title>
            <Stack spacing={3} sx={{ p: 3, pt: 0 }}>
              <CardForStamp
                description="豊富なパターンとカラーの組み合わせにより、デザイン性に優れています。"
                title="1. 豊富なバリエーション"
              />
              <CardForStamp
                description="店舗・テーマパーク・一般住宅・歩道・車道など、さまざまな場所に施工ができます。"
                title="2. 屋内・屋外の施工が可能"
              />
              <CardForStamp
                description="天然石やレンガなどの布設作業に比べ、生コン打設と同時仕上げのため工期を短縮できます。"
                title="3. 工期の短縮"
              />
            </Stack>
          </Section>
        </Stack>
      </Main>
    </App>
  );
};

export default StampPage;
