import React from "react";
import { styled, Typography } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  padding: 24,
  minWidth: 320,
  width: "100%",
  border: "solid 2px #535d60",

  display: "flex",
  flexDirection: "column",

  '& > * + *': {
    marginTop: 8
  },

  [theme.breakpoints.down("md")]: {
    padding: 16,
  },
}));

const CardForStamp = ({ description, title }) => {
  return (
    <Root>
      <Typography component="h3" fontWeight="bold" variant="h6">
        {title}
      </Typography>
      <Typography>
        {description}
      </Typography>
    </Root>
  );
};

export default CardForStamp;
